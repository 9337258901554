import React from 'react';
// import { Typography, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VideoPlayer from './components/VideoPlayer';
import Sidebar from './components/Sidebar';
import Notifications from './components/Notifications';

const useStyles = makeStyles(() => ({
  image: {
    height: 150,
    right: 20,
    top: 20,
    position: 'absolute',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundImage: 'url("https://public-pos-notifications-images.s3.amazonaws.com/bg.jpeg")',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img className={classes.image} src="https://public-pos-notifications-images.s3.amazonaws.com/logos/portal-logo-16213605-e209-4df0-98e8-1c115b51f75e.png" />
      <VideoPlayer />
      <Sidebar>
        <Notifications />
      </Sidebar>
    </div>
  );
};

export default App;
